/*
 * @Author: wangqs 
 * @description 企业担保
 * @Date: 2021-07-11 16:24:29 
 * @Last Modified by: wangqs
 * @Last Modified time: 2021-09-01 16:59:18
 */
<template>
  <div class="page-box box">
    <div class="title-box box">
      <img
        class="icon"
        src="@/assets/img/contract-icon-b.png"
        alt=""
      >
      企业担保信息
    </div>
    <div class="container-box box">
      <van-field
        v-model="form.name"
        required
        input-align="right"
        label="企业名称"
        placeholder="请输入企业名称"
      />

      <van-field
        name="radio"
        required
        input-align="right"
        label="证件类型"
      >
        <template #input>
          <van-radio-group
            v-model="form.cardType"
            direction="horizontal"
          >
            <van-radio name="3">
              统一社会信用代码
            </van-radio>
            <van-radio name="2">
              营业执照
            </van-radio>
          </van-radio-group>
        </template>
      </van-field>

      <van-field
        v-model="form.idCardNo"
        required
        input-align="right"
        label="证件号码"
        placeholder="请输入证件号码"
      />

      <van-field
        readonly
        required
        input-align="right"
        name="area"
        :value="addrStr"
        label="家庭地址"
        placeholder="点击选择省市区"
        @click="showArea = true"
      />
      <van-popup
        v-model="showArea"
        position="bottom"
      >
        <van-area
          :area-list="areaList"
          @confirm="onConfirmAddr"
          @cancel="showArea = false"
        />
      </van-popup>

      <van-field
        v-model="detailAddr"
        required
        input-align="right"
        label="详细地址"
        placeholder="请输入详细地址"
      />

      <van-field
        v-model="form.tel"
        required
        input-align="right"
        label="企业固定电话"
        placeholder="请输入固定电话"
      />

      <van-field
        v-model="form.operatorName"
        required
        input-align="right"
        label="经办人姓名"
        placeholder="请输入经办人姓名"
      />

      <van-field
        v-model="form.operatorMobile"
        required
        type="number"
        input-align="right"
        label="经办人手机号"
        max-length="11"
        placeholder="请输入手机号"
      />

      <van-field
        v-model="form.operatorIdCardNo"
        required
        input-align="right"
        label="经办人证件号码"
        placeholder="请输入证件号码"
      />

      <van-field
        v-model="form.legalName"
        required
        input-align="right"
        label="法定代表人"
        placeholder="请输入法定代表人"
      />

      <van-field
        v-model="form.fax"
        input-align="right"
        label="传真"
        placeholder="请输入传真"
      />

      <van-field
        v-model="form.email"
        input-align="right"
        label="邮箱"
        placeholder="请输入联系人邮箱"
      />
    </div>

    <van-button
      class="submit-btn"
      color="#51B464"
      round
      type="info"
      @click="submit"
    >
      提交
    </van-button>
    <form
      :action="faceData.action || 'https://dsptest.cpcn.com.cn/DSPLivenessWeb/InterfaceVI'"
      name="faceFormB"
      style="opacity: 0;position: absolute;left: -2000px;top:0;"
      method="POST"
    >
      <div>
        <input
          type="text"
          name="message"
          placeholder="message"
          :value="faceData.message"
        />
      </div>
      <div>
        <input
          type="text"
          name="signature"
          placeholder="signature"
          :value="faceData.signature"
        />
      </div>
      <div>
        <input
          id="dgtlEnvlp"
          type="text"
          name="dgtlenvlp"
          placeholder="dgtlenvlp"
          :value="faceData.dgtlenvlp"
        />
      </div>
      <div>
        <input
          id="signSN"
          type="text"
          name="signSN"
          placeholder="signSN"
          :value="faceData.signSN"
        />
      </div>
      <div>
        <input
          id="encryptSN"
          type="text"
          name="encryptSN"
          placeholder="encryptSN"
          :value="faceData.encryptSN"
        />
      </div>
    </form>
  </div>
</template>

<script>
import { areaList } from '@vant/area-data';
export default {
  name: 'OrgGuarantee',
  components: {
  },
  data () {
    return {
      areaList,
      form: {
        cardType: '3',
        type: '2',
        tel: '',
        name: '',
        idCardNo: '',
        operatorName: '',
        operatorMobile: '',
        operatorIdCardNo: '',
        email: '',
        addr: '',
        legalName: '',
        fax: ''
      },
      orderInfo: {},
      showArea: false,
      addrStr: '',
      detailAddr: '',
      faceData: {}
    }
  },
  computed: {
  },
  watch: {
    $route () {
      console.log(this.$route, 'route')
      if (this.$route.query.status == 1) {
        history.go(-3)
      }
    }
  },
  created () {
  },
  methods: {
    async submit () {
      let data = this.form
      data.addr = this.addrStr.replace(/\//g, ' ') + ' ' + this.detailAddr
      data.orderNo = this.$route.query.id || ''
      this.$toast.loading({
        message: '加载中',
        forbidClick: true,
        duration: 0
      });
      if (this.filterData()) {
        let resA = await this.$http.orgApi.orgGuaranteeAdd(data)
        if (resA.code === 200) {
          console.log(resA, 'res====')
          this.$toast.clear()
          // 人脸识别校验
          // let res = await this.$http.orderApi.orderFaceCheck({id: resA.msg.contractNo || '1'})
          let resB = await this.$http.orderApi.orderFaceOrgParams({id: resA.msg.id, orderNo: this.$route.query.id || ''})
          if (resB.code === 200) {
            this.faceData = resB.msg || {}
            this.$nextTick(() => {
              document.faceFormB && document.faceFormB.submit()
            })
          }
        }
      }
    },
    filterData () {
      if (!this.form.name) {
        this.$toast('请完善企业名称')
        return false
      } else if (!this.form.idCardNo) {
        this.$toast('请填写的证件号')
        return false
      } else if (this.form.idCardNo.length < 15) {
        this.$toast('请填写的证件号15或18位身份照号码')
        return false
      } else if (!this.addrStr) {
        this.$toast('请选择省市县')
        return false
      } else if (!this.detailAddr) {
        this.$toast('请完善详细地址')
        return false
      } else if (!this.form.tel) {
        this.$toast('请完善固定电话')
        return false
      } else if (!this.form.operatorName) {
        this.$toast('请完善经办人姓名')
        return false
      } else if (!this.form.operatorMobile) {
        this.$toast('请完善经办人手机号')
        return false
      } else if (!this.form.operatorIdCardNo) {
        this.$toast('请完善经办人证件号')
        return false
      } else if (this.form.operatorIdCardNo.length < 15) {
        this.$toast('请填写的经办人证件号15或18位')
        return false
      }  else if (!this.form.legalName) {
        this.$toast('请完善法定代表人')
        return false
      } else {
        return true
      }
    },
    onConfirmAddr (values) {
      this.addrStr = values
        .filter((item) => !!item)
        .map((item) => item.name)
        .join('/');
      this.orderInfo.provinceNo = values[0].code
      this.orderInfo.cityNo = values[1].code
      this.orderInfo.areaNo = values[2].code
      this.orderInfo.provinceName = values[0].name
      this.orderInfo.cityName = values[1].name
      this.orderInfo.areaName = values[2].name
      this.showArea = false;
    },
  }
}
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>
